@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Dosis|Open+Sans&display=swap');

body {
  margin: 0; background-color: #f3f3ef
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

#menu {
  background: linear-gradient(to right, #0FABA6, #6CC24A);
  width: 100%;
  min-width: 1300px;
  height: 40px;
}

.menu_item {
  padding: 10px;
  color: white;
}

.menu_item_left {
  float: left;
}

.menu_item_right {
  float: right;
}

#menu_divider {
  width: 100%; background: black;
}

.submenu_item {
  color: black;
}

.form-wrapper {
  margin-left: 15px;
  float: left;
}

.form-row-wrapper {
  margin-top: 10px;
  width: 25%;
}

.form-action-wrapper {
}

.table {
  margin-left: 35px;
  margin-top: 10px;
}

.table td {
  border-bottom: 1px solid #ddd;
}

#display-none {
  display: none;
}

.dropdownText:hover{
  background: #57bd5f;
}